// 导入axios实例
import httpRequest from './index'
import "./basData"
// 定义接口的传参
const url = 'https://netseaglobal-api.aucfan-cn.com/'//测试
// const url = 'https://fapi.aucfan-cn.com/'//正式
// const url = 'https://extend.aucfan-cn.com/'//需求域名
// const url = 'https://demand1.netsea-cn.com/'//需求域名



// // 登录
export function apiLogin(params: any) {
    return httpRequest({
        url: url + 'api/login/login',
        method: 'post',
        data: params,
    })
}
// 手机号登录 - 获取验证码
export function apiSms(params: any) {
    return httpRequest({
        url: url + 'api/login/sms',
        method: 'post',
        data: params,
    })
}

// 注册
export function apiRegister(params: any) {
    return httpRequest({
        url: url + 'api/login/register',
        method: 'post',
        data: params
    })
}


// 忘记密码
export function resetPassword(params: any) {
    return httpRequest({
        url: url + 'api/login/resetPassword',
        method: 'post',
        data: params
    })
}

// 获取用户信息
export function getUserInfo(params: any) {
    return httpRequest({
        url: url + 'api/user/getUserInfo',
        method: 'post',
        data: params
    })
}

// 编辑用户信息
export function updateUser(params: any) {
    return httpRequest({
        url: url + 'api/user/updateUser',
        method: 'post',
        data: params
    })
}
// 获取用户列表
export function get_address(params: any) {
    return httpRequest({
        url: url + 'api/address/get_address',
        method: 'post',
        data: params
    })
}
// 获取用户地址详情信息
export function get_address_info(params: any) {
    return httpRequest({
        url: url + 'api/address/get_address_info',
        method: 'post',
        data: params
    })
}
// 编辑|新增个人地址
export function save_address(params: any) {
    return httpRequest({
        url: url + 'api/address/save_address',
        method: 'post',
        data: params
    })
}
// 获取地区
export function get_region(params: any) {
    return httpRequest({
        url: url + 'api/com/get_region',
        method: 'post',
        data: params
    })
}
// 删除用户地址
export function addressDel(params: any) {
    return httpRequest({
        url: url + 'api/address/del',
        method: 'post',
        data: params
    })
}
// 获取协议
export function getAgreement(params: any) {
    return httpRequest({
        url: url + 'api/com/getAgreement',
        method: 'post',
        data: params
    })
}
// 购物车列表
export function cartList(params: any) {
    return httpRequest({
        url: url + 'api/cart/list',
        method: 'post',
        data: params
    })
}
// 商品树
export function getCateTree(params: any) {
    return httpRequest({
        url: url + 'api/com/getCateTree',
        method: 'post',
        data: params
    })
}

// 商品列表
export function goodsList(params: any) {
    return httpRequest({
        // url: url + 'api/goods/list',
        url: url + 'api/goodsSpu/list',
        method: 'post',
        data: params
    })
}


// 商品详情
export function goodsDetails(params: any) {
    return httpRequest({
        // url: url + 'api/goods/details',
        url: url + 'api/goodsSpu/details',
        method: 'post',
        data: params
    })
}

// 添加购物车
export function getStore(params: any) {
    return httpRequest({
        url: url + 'api/cart/store',
        method: 'post',
        data: params
    })
}
// 修改购物车
export function editCar(params: any) {
    return httpRequest({
        url: url + 'api/cart/edit',
        method: 'post',
        data: params
    })
}

//移除购物车
export function cartDel(params: any) {
    return httpRequest({
        url: url + 'api/cart/del',
        method: 'post',
        data: params
    })
}

// 商品数量 减少、添加
export function plus_or_minus(params: any) {
    return httpRequest({
        url: url + 'api/cart/plus_or_minus',
        method: 'post',
        data: params
    })
}

// 购物车商品订单预览
export function goodsOrderPre(params: any) {
    return httpRequest({
        url: url + 'api/goodsOrder/pre',
        method: 'post',
        data: params
    })
}
// 购物车创建订单
export function goodsOrderCreate(params: any) {
    return httpRequest({
        url: url + 'api/goodsOrder/create',
        method: 'post',
        data: params
    })
}

// 购物车选择金额
export function getAmount(params: any) {
    return httpRequest({
        url: url + 'api/cart/getAmount',
        method: 'post',
        data: params
    })
}

// 支付
export function pay(params: any) {
    return httpRequest({
        url: url + 'api/pay/payOrder',
        method: 'post',
        data: params
    })
}
// 设置默认地址
export function setDefault(params: any) {
    return httpRequest({
        url: url + 'api/address/setDefault',
        method: 'post',
        data: params
    })
}
// 商品详情-推荐商品
export function goodsRecommend(params: any) {
    return httpRequest({
        // url: url + 'api/goods/goodsRecommend',
        url: url + 'api/goodsSpu/goodsRecommend',
        method: 'post',
        data: params
    })
}
// 获取商品品牌
export function getGoodsBrand(params: any) {
    return httpRequest({
        url: url + 'api/com/getGoodsBrand',
        method: 'post',
        data: params
    })
}
// 商品购买订单预览
export function preGoods(params: any) {
    return httpRequest({
        url: url + 'api/goodsOrder/preGoods',
        method: 'post',
        data: params
    })
}
// 商品购买创建订单
export function createGoodsOrder(params: any) {
    return httpRequest({
        url: url + 'api/goodsOrder/createGoodsOrder',
        method: 'post',
        data: params
    })
}
// 其他订单
export function orderMain(params: any) {
    return httpRequest({
        url: url + 'api/order/main',
        method: 'post',
        data: params
    })
}

// 订单详情
export function mainDetails(params: any) {
    return httpRequest({
        url: url + 'api/order/mainDetails',
        method: 'post',
        data: params
    })
}
// 关闭-订单
export function orderCloseList(params: any) {
    return httpRequest({
        url: url + 'api/order/list',
        method: 'post',
        data: params
    })
}
// 取消订单
export function goodsOrderCancel(params: any) {
    return httpRequest({
        url: url + 'api/goodsOrder/cancel',
        method: 'post',
        data: params
    })
}
//关闭-订单详情
export function cancelOrderDetails(params: any) {
    return httpRequest({
        url: url + 'api/order/details',
        method: 'post',
        data: params
    })
}

//退款
export function refundCreate(params: any) {
    return httpRequest({
        url: url + 'api/refund/create',
        method: 'post',
        data: params
    })
}

//维权列表
export function refundList(params: any) {
    return httpRequest({
        url: url + 'api/refund/list',
        method: 'post',
        data: params
    })
}
// 维权订单填写物流信息
export function fillLogistics(params: any) {
    return httpRequest({
        url: url + 'api/refund/fillLogistics',
        method: 'post',
        data: params
    })
}
// 维权订单填写物流信息
export function uploadFile(params: any) {
    return httpRequest({
        url: url + 'api/com/uploadFile',
        method: 'post',
        data: params
    })
}
// 工作台
export function panel(params: any) {
    return httpRequest({
        url: url + 'api/panel/panel',
        method: 'post',
        data: params
    })
}
// 撤销维权
export function cancelRefund(params: any) {
    return httpRequest({
        url: url + 'api/refund/cancel',
        method: 'post',
        data: params
    })
}
// 维权详情
export function refundDetails(params: any) {
    return httpRequest({
        url: url + 'api/refund/details',
        method: 'post',
        data: params
    })
}
// 用户删除订单
export function goodsOrderDelete(params: any) {
    return httpRequest({
        url: url + 'api/order/delete',
        method: 'post',
        data: params
    })
}
// 确认收货
export function orderDelivery(params: any) {
    return httpRequest({
        url: url + 'api/order/delivery',
        method: 'post',
        data: params
    })
}

// 确认收货
export function refundDelete(params: any) {
    return httpRequest({
        url: url + 'api/refund/delete',
        method: 'post',
        data: params
    })
}

// 订单状态
export function getOrderPayStatus(params: any) {
    return httpRequest({
        url: url + 'api/pay/getOrderPayStatus',
        method: 'post',
        data: params
    })
}

// 获取全部订单
export function orderAllList(params: any) {
    return httpRequest({
        url: url + 'api/order/allList',
        method: 'post',
        data: params
    })
}
// 获取购物车数量
export function getCartGoodsCount(params: any) {
    return httpRequest({
        url: url + 'api/cart/getCartGoodsCount',
        method: 'post',
        data: params
    })
}

//获取公告
export function getNoticeMsg(params: any) {
    return httpRequest({
        url: url + 'api/msg/getNotice',
        method: 'post',
        data: params
    })
}

// 设置全部已读
export function setNoticeRead(params: any) {
    return httpRequest({
        url: url + 'api/msg/setNoticeRead',
        method: 'post',
        data: params
    })
}
// 公告详情
export function getNoticeInfo(params: any) {
    return httpRequest({
        url: url + 'api/msg/getNoticeInfo',
        method: 'post',
        data: params
    })
}
// 获取消息数量
export function getMsgCount(params: any) {
    return httpRequest({
        url: url + 'api/msg/getMsgCount',
        method: 'post',
        data: params
    })
}

// 获取审批消息
export function getApproval(params: any) {
    return httpRequest({
        url: url + 'api/msg/getApproval',
        method: 'post',
        data: params
    })
}
// 审批详情
export function getApprovalInfo(params: any) {
    return httpRequest({
        url: url + 'api/msg/getApprovalInfo',
        method: 'post',
        data: params
    })
}
// 获取用户主体资质
export function getMain(params: any) {
    return httpRequest({
        url: url + 'api/com/getMain',
        method: 'post',
        data: params
    })
}
// 获取用户经营类目
export function getBusiness(params: any) {
    return httpRequest({
        url: url + 'api/com/getBusiness',
        method: 'post',
        data: params
    })
}

// 编辑用户主体信息
export function updateUserDetails(params: any) {
    return httpRequest({
        url: url + 'api/user/updateDetails',
        method: 'post',
        data: params
    })
}
// 寻购记录
export function goods_searchList(params: any) {
    return httpRequest({
        url: url + 'api/goods_search/list',
        method: 'post',
        data: params
    })
}
// 发起寻购
export function goods_searchAdd(params: any) {
    return httpRequest({
        url: url + 'api/goods_search/add',
        method: 'post',
        data: params
    })
}
// 寻购详情
export function goods_searchDetails(params: any) {
    return httpRequest({
        url: url + 'api/goods_search/details',
        method: 'post',
        data: params
    })
}
//采购统计
export function panelStatistics(params: any) {
    return httpRequest({
        url: url + 'api/panel/statistics',
        method: 'post',
        data: params
    })
}
//新-开票记录
export function orderInvoice(params: any) {
    return httpRequest({
        url: url + 'api/order/invoice',
        method: 'post',
        data: params
    })
}
//新-开票记录
export function getInvoiceOrder(params: any) {
    return httpRequest({
        url: url + 'api/order/getInvoiceOrder',
        method: 'post',
        data: params
    })
}

//新-导出商品信息
export function exportProductInfo(params: any) {
    return httpRequest({
        // url: url + 'api/goods/exportProductInfo',
        url: url + 'api/goodsSpu/exportProductInfo',
        method: 'post',
        data: params
    })
}

//新-申请开发票
export function orderApply(params: any) {
    return httpRequest({
        url: url + 'api/order/apply',
        method: 'post',
        data: params
    })
}

//新-获取顶部新增公告
export function getTopNotice(params: any) {
    return httpRequest({
        url: url + 'api/com/getTopNotice',
        method: 'post',
        data: params
    })
}

//新-获取顶部新增公告
export function detailsByShowGoods(params: any) {
    return httpRequest({
        url: url + 'api/goods/detailsByShowGoods',
        method: 'post',
        data: params
    })
}
//新-获取店铺信息
export function storegetDetails(params: any) {
    return httpRequest({
        url: url + 'api/store/getDetails',
        method: 'post',
        data: params
    })
}
// 获取平台联系方式
export function getContact(params: any) {
    return httpRequest({
        url: url + 'api/com/getContact',
        method: 'post',
        data: params
    })
}
// 再来一单
export function preGoodsAgain(params: any) {
    return httpRequest({
        url: url + 'api/goodsOrder/preGoods',
        method: 'post',
        data: params
    })
}
